@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.event-tooltip-content {
  background-color: #414141;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  padding: 10px;
  max-width: 300px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff;
}

.event-tooltip-content .event-name {
  font-weight: 700;
}
